import { LinearProgress, Switch } from '@material-ui/core'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import {MdDelete} from 'react-icons/md'
import { NotificationManager } from 'react-notifications'
import DeletePopup from '../../components/DeletePopup'
import Config from '../../Config'

function Review() {

    const [Posts, setPosts] = useState([])
    const [Loading, setLoading] = useState(true)
    const [DeletePopUpState, setDeletePopUpState] = useState(false)
    const [DeletePost, setDeletePost] = useState({})

    function OnDeleteConfirm() {
        setDeletePopUpState(false)
        if(DeletePost.id !== '' && DeletePost.index !== ''){
            let Id = DeletePost.id;
            let index = DeletePost.index;
            setPosts(chips => chips.filter(chip => Posts.indexOf(chip) !== index) )
            axios({
                method: 'DELETE',
                url: Config.server+'api/deleteReview',
                headers: {
                    authorization: 'Bearer ' + token
                },
                data: {
                    id: Id
                }
            })
            .then(res => {
                document.getElementById(Id).style.display = 'none'
                NotificationManager.success(res.data.message, res.data.status);
            })
            .catch(err => {
                if(err.response){
                    NotificationManager.error(err.response.data.message);
                }
            })
        }
    }

    function handleDeletePopUpStateChange(state) {
        setDeletePopUpState(state)
    }

    let token = window.localStorage.getItem('jwareAutomationSerialNo')

    useEffect(() => {
        setLoading(true)
        axios({
            method: 'GET',
            url: Config.server+'api/getReviews'
        })
        .then(res => {
            setPosts(res.data.data)
            NotificationManager.success('Reviews Data Loaded', res.data.status);
            setLoading(false)
        })
        .catch(err => {
            if(err.response){
                NotificationManager.error(err.response.data.message);
            }else{
                NotificationManager.error('Error');
            }
        })

    }, [])

    function onReviewStateChange(e, Id) {
        let validation = e.target.checked;

        axios({
            method: 'PATCH',
            url: Config.server+'api/validateReview',
            headers: {
                authorization: 'Bearer ' + token
            },
            data: {
                id: Id,
                valid: validation
            }
        })
        .then(res => {
            NotificationManager.success(res.data.message, res.data.status);
        })
        .catch(err => {
            if(err.response){
                NotificationManager.error(err.response.data.message);
            }else{
                NotificationManager.error('Error');
            }
        })
    }

    function hanldeOnReviewDelete(Id, index) {
        setDeletePopUpState(true)
        setDeletePost({
            id: Id,
            index: index
        })   
    }

    return (
        <div id="review" className="container">

            {
                Loading ? <div className="show_flex_container"><LinearProgress className="show_liner_loading" /></div> : 

                <div className="review_container">
                    
                    {   Posts.length === 0 ? <div className="show_flex_container">
                            <img src="/images/empty.svg" alt="empty" className="empty_image" />
                            <h3>Its Empty Here</h3>
                            </div> :
                        Posts.map((post, index) => {

                            return (
                                <div key={post._id} id={post._id} className="review_card">
                                    <div className="review_relate_container">
                                        <img src={Config.server+post.blog.image} alt="post preview" className="review_image" />
                                        <h3 className="review_title">{post.blog.title}</h3>
                                    </div>
                                    <div className="review_content">
                                        <h2 className="review_name">
                                            By <span>{post.name}</span>
                                        </h2>
                                        <p className="review_email">{post.email}</p>
                                        <p className="review_description">{post.review}</p>
                                        <div className="review_buttons">
                                            <Switch
                                                defaultChecked={post.valid}
                                                onChange={(e) => {onReviewStateChange(e, post._id)}}
                                                name="checkedA"
                                                color="primary"
                                            />
                                            <MdDelete onClick={() => hanldeOnReviewDelete(post._id, index)} className="review_delete"/>
                                        </div>
                                    </div>
                                </div>

                            )
                        })
                    }

                </div>
            }

            <DeletePopup state={DeletePopUpState} onClick={() => OnDeleteConfirm()} onStateChange={(state) => handleDeletePopUpStateChange(state)}  />
            
        </div>
    )
}

export default Review
