import axios from 'axios'
import React, { useEffect } from 'react'
import { NotificationManager } from 'react-notifications'
import Config from '../../Config'

function SocialMedia() {
    
    let token = window.localStorage.getItem('jwareAutomationSerialNo')

    useEffect(() => {

        axios({
            method: 'GET',
            url: Config.server+'api/getSocialMedia'
        })
        .then(res => {
            handleFieldValues(res.data.data)
            NotificationManager.success(res.data.message, res.data.status);
        })
        .catch(err => {
            console.log(err)
            if(err.response){
                NotificationManager.error(err.response.data.message);
            }else{
                NotificationManager.error('Error');
            }
        })

    }, [])

    function handleFieldValues(data) {
        let list = ['facebook', 'instagram', 'twitter', 'linkedIn']

        list.forEach(item => {
            if(data[item]){
                document.getElementById(`socialMedia-${item}`).defaultValue = data[item]
            }
        })
    }

    function handleOnUpdateClick() {
        let inputs = document.getElementsByClassName('socialMedia-data')

        let Obj = {}

        for (let input of inputs){
            if(input.value){
                let name = input.id.split("-")[1]
                Obj[name] = input.value
            }
        }

        axios({
            method: 'PUT',
            url: Config.server+'api/updateSocialMedia',
            headers: {
                authorization: 'Bearer ' + token
            },
            data: Obj
        })
        .then(res => {
            NotificationManager.success(res.data.message, res.data.status);
        })
        .catch(err => {
            console.log(err)
            if(err.response){
                NotificationManager.error(err.response.data.message);
            }else{
                NotificationManager.error('Error');
            }
        })
        
    }



    return (
        <div id="socialMedia" className="container">

            <h1 className="socialMedia_title">Social Media</h1>

            <div className="socialMedia_wrapper">
                <h3 className="socialMedia_name">Facebook</h3>
                <input id="socialMedia-facebook" type="text" className="input socialMedia-data" data-valid="true" />
            </div>
            <div className="socialMedia_wrapper">
                <h3 className="socialMedia_name">Instagram</h3>
                <input id="socialMedia-instagram" type="text" className="input socialMedia-data" data-valid="true" />
            </div>
            <div className="socialMedia_wrapper">
                <h3 className="socialMedia_name">LinkedIn</h3>
                <input id="socialMedia-linkedIn" type="text" className="input socialMedia-data" data-valid="true" />
            </div>
            <div className="socialMedia_wrapper">
                <h3 className="socialMedia_name">Twitter</h3>
                <input id="socialMedia-twitter" type="text" className="input socialMedia-data" data-valid="true" />
            </div>

            <button onClick={() => handleOnUpdateClick()} className="button">Update</button>
        </div>
    )
}

export default SocialMedia
