import axios from 'axios'
import React from 'react'
import { NotificationManager } from 'react-notifications'
import {server} from '../Config'

function Settings() {

    let email = window.localStorage.getItem('jwareAutomationEmail')

    let token = window.localStorage.getItem('jwareAutomationSerialNo')

    function handleOnPasswordReset(){

        let password = document.getElementById('settings_password')
        let cpassword = document.getElementById('settings_cpassword')

        if(password.value === cpassword.value && password.value !== '' && cpassword !== ''){
            axios({
                method: "PATCH",
                url: server+'api/updateUser',
                headers: {
                    authorization: 'Bearer ' + token
                },
                data: {
                    email: email,
                    password: password.value
                } 
            })
            .then(res => {
                password.value = ''
                cpassword.value = ''
                NotificationManager.success(res.data.message, res.data.status);
            })
            .catch(err => {
                if(err.response){
                    NotificationManager.error(err.response.data.message);
                }else{
                    NotificationManager.error('Error');
                }
            })
        }else if(password.value !== cpassword.value){
            NotificationManager.error("Passwords doesn't match");
        }
        else{
            NotificationManager.error("Both fields are required");
        }
    }

    return (
        <div id="settings" className="container">
            <h1 className="settings_title">Settings</h1>
            <div className="settings_email_wrapper">
                <h4 className="settings_email_title">Email:</h4>
                <p className="settings_email">{email}</p>
            </div>
            <h4>Reset Password</h4>
            <div className="settings_password_wrapper">
                <input id="settings_password" type="password" className="input" placeholder="Password" data-valid="true"/>
                <input id="settings_cpassword" type="password" className="input" placeholder="Confirm Password" data-valid="true" />
                <button onClick={() => handleOnPasswordReset()} id="settings_btn" className="button"> Reset </button>
            </div>
        </div>
    )
}

export default Settings
