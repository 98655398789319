/* eslint-disable */

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { IoSearch } from 'react-icons/io5'
import axios from 'axios'
import Config, { server } from '../../Config'
import { LinearProgress } from '@material-ui/core'
import { NotificationManager } from 'react-notifications'
import DeletePopup from '../../components/DeletePopup'

function Blogs() {

    const [Posts, setPosts] = useState([])
    const [Loading, setLoading] = useState(true)
    const [DeletePopUpState, setDeletePopUpState] = useState(false)
    const [DeletePostId, setDeletePostId] = useState(null)

    function OnDeleteConfirm() {
        setDeletePopUpState(false)
        if (DeletePostId) {
            let Id = DeletePostId;
            let post = document.getElementById(Id)

            axios({
                method: "Delete",
                url: server + 'api/deleteBlog',
                headers: {
                    authorization: 'Bearer ' + token
                },
                data: {
                    id: Id
                }
            })
                .then(res => {
                    NotificationManager.success(res.data.message, res.data.status);
                    post.style.display = 'none'
                })
                .catch(err => {
                    if (err.response) {
                        NotificationManager.error(err.response.data.message);
                    } else {
                        NotificationManager.error('Error');
                    }
                })
        }
    }

    function handleDeletePopUpStateChange(state) {
        setDeletePopUpState(state)
    }

    let token = window.localStorage.getItem('jwareAutomationSerialNo')

    let path = window.location.pathname.split('/')[1]

    useEffect(() => {
        setLoading(true)
        if (path === 'blogs') {
            axios({
                method: "POST",
                url: server + 'api/getAllBlogsSortBy',
                data: {
                    field: 'createdAt',
                    sortBy: 'desc'
                }
            })
                .then(res => {
                    setPosts(res.data.data)
                    NotificationManager.success('Blog Data Loaded', res.data.status);
                    setLoading(false)
                })
                .catch(err => {
                    if (err.response) {
                        NotificationManager.error(err.response.data.message);
                    } else {
                        NotificationManager.error('Error');
                    }
                })
        }
    }, [path])

    function handleOnSearch() {
        let value = document.getElementById('show_search_txt').value

        if (path === 'blogs') {
            setLoading(true)
            axios({
                method: "POST",
                url: server + 'api/getBlogFilterd',
                data: {
                    field: 'title',
                    value: value,
                    sort: {
                        field: 'createdAt',
                        order: 'desc'
                    }
                }
            })
                .then(res => {
                    setPosts(res.data.data)
                    if (res.data.data.length === 0) {
                        NotificationManager.info('No Data Found');
                    } else {
                        NotificationManager.success('Blog Data Loaded', res.data.status);
                    }
                    setLoading(false)
                })
                .catch(err => {
                    if (err.response) {
                        NotificationManager.error(err.response.data.message);
                    } else {
                        NotificationManager.error('Error');
                    }
                })
        }
    }

    function handleOnValueChange(e) {
        if (e.target.value == '') {
            setLoading(true)
            if (path === 'blogs') {
                axios({
                    method: "POST",
                    url: server + 'api/getAllBlogsSortBy',
                    data: {
                        field: 'createdAt',
                        sortBy: 'desc'
                    }
                })
                    .then(res => {
                        setPosts(res.data.data)
                        if (res.data.data.length === 0) {
                            NotificationManager.info('No Data Found');
                        } else {
                            NotificationManager.success('Blog Data Loaded', res.data.status);
                        }
                        setLoading(false)
                    })
                    .catch(err => {
                        if (err.response) {
                            NotificationManager.error(err.response.data.message);
                        } else {
                            NotificationManager.error('Error');
                        }
                    })
            }
        }
    }

    function handleOnDelete(Id) {
        setDeletePopUpState(true)
        setDeletePostId(Id)
    }

    return (
        <div id="show" className="container">

            {/* show search start */}
            <div className="show_search_wrapper">
                <input id="show_search_txt" onChange={(e) => handleOnValueChange(e)} className="input" placeholder="search titles ..." data-valid="true" />
                <button id="show_search_btn" className="button" onClick={() => handleOnSearch()}><IoSearch /></button>
            </div>
            {/* show search end */}

            {
                Loading ? <div className="show_flex_container"><LinearProgress className="show_liner_loading" /></div> :

                    Posts.length === 0 ? <div className="show_flex_container">
                        <img src="/images/empty.svg" alt="empty" className="empty_image" />
                        <h3>Its Empty Here</h3>
                    </div> :
                        <div className="show_container">
                            {
                                Posts.map((post) => {
                                    return (
                                        <div key={post._id} id={post._id} className="show_card">
                                            <img src={Config.server + post.image} alt="show preview" className="show_image" />

                                            <div className="show_content">
                                                <h4 className="show_title">{post.title}</h4>

                                                <div className="show_buttons">
                                                    <Link to={post.html ? `/custom-blog/${post._id}` : `/updateBlog/${post._id}`} className="button">Update</Link>
                                                    <div onClick={() => handleOnDelete(post._id)} className="delete button">Delete</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>


            }

            <DeletePopup state={DeletePopUpState} onClick={() => OnDeleteConfirm()} onStateChange={(state) => handleDeletePopUpStateChange(state)} />

        </div>
    )
}

export default Blogs
