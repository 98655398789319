import React from 'react'

function NotFound() {
    return (
        <div id="notFound" className="container">
            <h1>404</h1>
            <h2>Page Not Found</h2>
        </div>
    )
}

export default NotFound
