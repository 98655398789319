/* eslint-disable */

import React, { useEffect, useState } from 'react'
import {FaPlus, FaTimes} from 'react-icons/fa'
import {BsImage} from 'react-icons/bs'
import axios from 'axios'
import { NotificationManager } from 'react-notifications'
import {server} from '../../Config'
import { LinearProgress, Slider } from '@material-ui/core'
import uuid from 'react-uuid'

function TeamForm() {

    const [OldTags, setOldTags] = useState([])
    const [Tags, setTags] = useState([])
    const [Loading, setLoading] = useState(false)

    let token = window.localStorage.getItem('jwareAutomationSerialNo')

    const postId = window.location.pathname.split('/')[2]

    useEffect(() => {

        if(postId){
            setLoading(true)
            axios({
                method: "GET",
                url: server+'api/getTeamMate/'+postId
            })
            .then(res => {
                setLoading(false)
                if(res.data.data.length === 0){
                    NotificationManager.info("Data Not Found");
                }else{
                    setDataToFields(res.data.data)
                }
            })
            .catch(err => {
                console.log(err)
                if(err.response){
                    NotificationManager.error(err.response.data.message);
                }else{
                    NotificationManager.error('Error');
                }
            })
        }
    }, [])

    function handleImageFile(e, labelId, previewId) {
        let target = e.target;
        let label = document.getElementById(labelId)
        let preview = document.getElementById(previewId)
        let files = target.files;
        const reader = new FileReader();

        reader.addEventListener("load", function () {
            // convert image file to base64 string
            preview.dataset.active = "true"
            preview.src = reader.result;
        }, false);

        if(files[0]){
            reader.readAsDataURL(files[0]);
            label.textContent = files[0].name
        }
    }

    function handleOnAddTag(e, inputId, countId) {
        e.preventDefault()
        let input = document.getElementById(inputId)
        let count = document.getElementById(countId)
        let tag = input.value.trim()
        
        if(tag !== ''){
            setTags([...Tags, {id: uuid(), key: Tags.length, label: [tag, count.textContent]}])
            input.value = ''
        }

    }

    function validateInput(inputs) { 

        var emailCheck=/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;   

        for (let input of inputs){
            
            if(!input.required) continue
            
            if(postId){
                if(input.value === '' && input.defaultValue !==''){
                    NotificationManager.error(`${input.id.split('-')[1]} is requied`)
                    input.dataset.valid = "false"
                    return false
                }
                if(input.type === 'email'){
                    if(!emailCheck.test(input.value)){
                        input.dataset.valid = "false"
                        return false
                    }
                }
            }else{
                if(input.value === ''){
                    NotificationManager.error(`${input.id.split('-')[1]} is requied`)
                    input.dataset.valid = "false"
                    return false
                }

                if(input.type === 'email'){
                    if(!emailCheck.test(input.value)){
                        input.dataset.valid = "false"
                        return false
                    }
                }
            }
        }

        if(Tags.length === 0 && OldTags.length === 0){
            NotificationManager.error(`Skills are requied`)
            return false
        }

        return true

    }

    function handleOnValueChange(value) {
        let label = document.getElementById('TeamForm_skill_value')
        if(label){
            if(value >= 0){
                label.textContent = value
            }
        }
    }

    function setDataToFields(data) {
        let inputs = document.getElementsByClassName('TeamForm-data')
        for (let input of inputs){
            let title = input.id.split('-')[1]
            if(input.type === 'file'){
                let img = document.getElementById('TeamForm-image-preview')
                img.dataset.active = "true"
                img.src = server+data.image
            }else if(input.type === 'date'){
                let splitDate = data.date.split('/')
                input.value = `${splitDate[2]}-${("0"+splitDate[0]).slice(-2)}-${("0"+splitDate[1]).slice(-2)}`

            }else if(title === 'skills'){
                setOldTags(data.skills)
            }
            else{
                input.defaultValue = data.[title]
                input.value = data.[title]
            }
        }
    }

    function setFormData(inputs) {
        let data = new FormData();

        if(postId){
            data.append('id', postId)
            for (let input of inputs){
                let title = input.id.split('-')[1]
                if(input.value !== input.defaultValue){
                    if(input.type === 'file'){
                        data.append(title, input.files[0])
                    }else if(input.type === 'date'){
                        let splitDate = input.value.split('-')
                        data.append(title, `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`)
                    }
                    else{
                        data.append(title, input.value)
                    }
                }else if(title === 'skills'){
                    let TagList = []
                    OldTags.forEach(tag => {
                        TagList.push(tag)
                    })
                    Tags.forEach(dataTag => {
                        TagList.push(dataTag.label)
                    })

                    TagList.forEach((inTag, index) => {
                        data.append(`skills[${index}]`, inTag[0])
                        data.append(`skills[${index}]`, inTag[1])
                    })
                }
            }
        }else{
            for (let input of inputs){
                let title = input.id.split('-')[1]
                if(input.value){
                    if(input.type === 'file'){
                        data.append(title, input.files[0])
                    }else if(input.type === 'date'){
                        let splitDate = input.value.split('-')
                        data.append(title, `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`)
                    }
                    else{
                        data.append(title, input.value)
                    }
                }else if(title === 'skills'){
                    Tags.forEach((dataTag,index) => {
                        data.append(`skills[${index}]`, dataTag.label[0])
                        data.append(`skills[${index}]`, dataTag.label[1])
                    })
                }
            }
        }

        return data
    }

    function handleOnSubmit(e) {
        let inputs = document.getElementsByClassName('TeamForm-input')
        let data = document.getElementsByClassName('TeamForm-data')

        e.preventDefault()
        if(validateInput(inputs)){

            let formData = setFormData(data)

            if(postId){
                axios({
                    method: "PATCH",
                    url: server+'api/updateTeamMate',
                    headers: {
                        authorization: 'Bearer ' + token
                    },
                    data: formData
                })
                .then(res => {
                    NotificationManager.success(res.data.message, res.data.status);
                    window.location.reload();
                })
                .catch(err => {
                    if(err.response){
                        NotificationManager.error(err.response.data.message);
                    }else{
                        NotificationManager.error('Error');
                    }
                })
            }else{
                axios({
                    method: "POST",
                    url: server+'api/addTeamMate',
                    headers: {
                        authorization: 'Bearer ' + token
                    },
                    data: formData
                })
                .then(res => {
                    NotificationManager.success(res.data.message, res.data.status);
                    window.location.reload();
                })
                .catch(err => {
                    if(err.response){
                        NotificationManager.error(err.response.data.message);
                    }else{
                        NotificationManager.error('Error');
                    }
                })
            }
        }
    }


    function handleOnTagRemove(chipToDelete) {
        setTags((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    }
    function handleOnOldTagRemove(chipToDelete, index) {
        setOldTags((chips) => chips.filter((chip) => OldTags.indexOf(chip) !== OldTags.indexOf(chipToDelete)));

        if(document.getElementById(`BlogForm_tag_${index}`)){
            document.getElementById(`BlogForm_tag_${index}`).style.display = 'none'
        }
    }

    return (
        <div id="TeamForm" className="container">

            <h1 className="main_title"> Team </h1>

            {
                Loading ? <div className="show_flex_container"><LinearProgress className="show_liner_loading" /></div>  : 
                        <form method="POST" id="TeamForm-form-wrapper">
                            {/* input start */}
                            <div className="TeamForm_input_wrapper">
                                <h3 className="TeamForm_title">Name</h3>
                                <input onChange={(e) => { if(e.target.value !== '') {e.target.dataset.valid="true"} } } data-valid="true" required type="text" id="TeamForm-name" className="input TeamForm-data TeamForm-input" />
                            </div>
                            {/* input end */}
                            {/* input start */}
                            <div className="TeamForm_input_wrapper">
                                <h3 className="TeamForm_title">Phone No.</h3>
                                <input data-valid="true" type="text" id="TeamForm-phone" className="input TeamForm-data TeamForm-input" />
                            </div>
                            {/* input end */}
                            {/* input start */}
                            <div className="TeamForm_input_wrapper">
                                <h3 className="TeamForm_title">Email</h3>
                                <input onChange={(e) => { if(e.target.value !== '') {e.target.dataset.valid="true"} } } data-valid="true" required type="email" id="TeamForm-email" className="input TeamForm-data TeamForm-input" />
                            </div>
                            {/* input end */}
                            {/* input start */}
                            <div className="TeamForm_input_wrapper">
                                <h3 className="TeamForm_title">Category</h3>
                                <input onChange={(e) => { if(e.target.value !== '') {e.target.dataset.valid="true"} } } data-valid="true" required  type="text" id="TeamForm-category" className="input TeamForm-data TeamForm-input" />
                            </div>
                            {/* input end */}
                            {/* input start */}
                            <div className="TeamForm_input_wrapper">
                                <h3 className="TeamForm_title">Skills</h3>
                                <div className="TeamForm_tags_content_wrapper">
                                    <Slider defaultValue={0} getAriaValueText={handleOnValueChange} id="TeamForm_skills_perecentage" className="input TeamForm-data TeamForm-input" aria-labelledby="continuous-slider" valueLabelDisplay="auto" min={0} max={100} />
                                    <p id="TeamForm_skill_value">0</p><span>%</span>
                                </div>
                                <div className="TeamForm_tags_content_wrapper">
                                    <input onChange={(e) => { if(e.target.value !== '') {e.target.dataset.valid="true"} } } data-valid="true" type="text" id="TeamForm-skills" className="input TeamForm-data" />
                                    <button onClick={(e) => handleOnAddTag(e, 'TeamForm-skills', 'TeamForm_skill_value' )} className="button"> <FaPlus/></button>
                                </div>

                                <ul id="TeamForm_display_tags" className="display_tags_wrapper">
                                {
                                    OldTags.map((data, index) => {
                                        return (
                                            <li key={index} id={`TeamForm_tag_${index}`} className="TeamForm_tag" > {data[0]}-{data[1]} <FaTimes className="TeamForm_tag_exit" onClick={() => handleOnOldTagRemove(data,index)} /> </li>
                                        )
                                    })
                                }
                                {
                                    Tags.map((data) => {
                                        return (
                                            <li key={data.key} id={`TeamForm_tag_${data.id}`} className="TeamForm_tag" > {data.label[0]}-{data.label[1]} <FaTimes className="TeamForm_tag_exit" onClick={() => handleOnTagRemove(data)} /> </li>
                                        )
                                    })
                                }
                                </ul>
                            </div>
                            {/* input end */}
                            {/* input start */}
                            <div className="TeamForm_input_wrapper">
                                <h3 className="TeamForm_title">Image</h3>
                                <div className="TeamForm_image_wrapper">
                                    {/* label for image */}
                                    <label htmlFor="TeamForm-image" id="TeamForm-image-label" data-active="true"><BsImage id="TeamForm-label-icon"/></label>
                                    {/* input image */}
                                    <input onChange={(e) => { if(e.target.value !== '') {e.target.dataset.valid="true"} } } data-valid="true" required onChange={ (e) => handleImageFile(e, 'TeamForm-image-label', 'TeamForm-image-preview' )} type="file" accept="image/png, image/jpeg" id="TeamForm-image" className="input TeamForm-data TeamForm-input" />
                                    {/* preview */}
                                    <img src="" alt="preview" id="TeamForm-image-preview" data-active="false" />
                                </div>
                            </div>
                            {/* input end */}
                            {/* input start */}
                            <div className="TeamForm_input_wrapper">
                                <h3 className="TeamForm_title">About</h3>
                                <textarea onChange={(e) => { if(e.target.value !== '') {e.target.dataset.valid="true"} } } data-valid="true" required type="text" id="TeamForm-description" className="input TeamForm-data TeamForm-input"></textarea>
                            </div>
                            {/* input end */}
                            {/* input start */}
                            <div className="TeamForm_input_wrapper">
                                <h3 className="TeamForm_title">Website</h3>
                                <input data-valid="true"  type="text" id="TeamForm-website" className="input TeamForm-data TeamForm-input" />
                            </div>
                            {/* input end */}
                            {/* input start */}
                            <div className="TeamForm_input_wrapper">
                                <h3 className="TeamForm_title">Since (year)</h3>
                                <input onChange={(e) => { if(e.target.value !== '') {e.target.dataset.valid="true"} } } data-valid="true" required type="number" id="TeamForm-since" className="input TeamForm-data TeamForm-input" />
                            </div>
                            {/* input end */}
                            {/* input start */}
                            <div className="TeamForm_input_wrapper">
                                <h3 className="TeamForm_title">Address</h3>
                                <input data-valid="true" type="text" id="TeamForm-address" className="input TeamForm-data TeamForm-input" />
                            </div>
                            {/* input end */}
                            {/* input start */}
                            <div className="TeamForm_input_wrapper">
                                <h3 className="TeamForm_title">Years of Experience</h3>
                                <input onChange={(e) => { if(e.target.value !== '') {e.target.dataset.valid="true"} } } data-valid="true" required type="number" id="TeamForm-experience" className="input TeamForm-data TeamForm-input" />
                            </div>
                            {/* input end */}
                            {/* input start */}
                            <div className="TeamForm_input_wrapper">
                                <h3 className="TeamForm_title">Carrer Description</h3>
                                <textarea onChange={(e) => { if(e.target.value !== '') {e.target.dataset.valid="true"} } } data-valid="true" required type="text" id="TeamForm-careerDescription" className="input TeamForm-data TeamForm-input"></textarea>
                            </div>
                            {/* input end */}

                            <input type="submit" className="button" onClick={(e) => handleOnSubmit(e,'TeamForm-form-wrapper', 'TeamForm_display_tags')} value="submit" />
                        </form>
            } 
        </div>
    )
}

export default TeamForm
