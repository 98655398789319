import React from 'react'
import { FaTimes } from 'react-icons/fa'

function DeletePopup({state, onClick, onStateChange}) {

    function handleOnExit() {
        onStateChange(false)
    }

    function handleOnClick() {
        onClick(true)
    }

    return (
        <div className="deletePopup container" data-active={state} >

            <div className="deletePopup_container">

                <div className="top">
                    <h3 className="title">DELETE</h3>
                    <FaTimes className="exit_icon" onClick={() => handleOnExit()} />
                </div>

                Are you sure you want to delete this item?

                <div className="button_wrap">
                    <button className="button" onClick={() => handleOnClick()} >Yes</button>
                    <button className="button" onClick={() => handleOnExit()} >No</button>
                </div>
            </div>
            
        </div>
    )
}

export default DeletePopup
