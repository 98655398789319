import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {FaBars, FaTimes} from 'react-icons/fa'
import {AiFillSetting} from 'react-icons/ai'
import {FiLogOut} from 'react-icons/fi'

function Navbar() {
    const [Active, setActive] = useState('')
    const [Menu, setMenu] = useState(false)

    let role = window.localStorage.getItem('jwareAutomationRole')

    useEffect(() => {

        let pathName = window.location.pathname.split('/')[1]

        setActive(`nav-${pathName}`)

    }, [])

    const handleMenu = (bool) => {
        if(bool === false){
            setMenu(bool)
            document.getElementById('navabr_links').dataset.active = bool
        }else{
            setMenu(!Menu)
            document.getElementById('navabr_links').dataset.active = !Menu
        }
    }

    function handleOnNavItemClick(e) {
        setActive(e.target.id)
        handleMenu(false)
    }

    function handleOnLogout() {
        window.localStorage.removeItem('jwareAutomationStatus')
        window.localStorage.removeItem('jwareAutomationSerialNo')
        window.localStorage.removeItem('jwareAutomationRole')
        window.localStorage.removeItem('jwareAutomationDate')
        window.localStorage.removeItem('jwareAutomationEmail')
        window.location.assign(window.location.origin)
    }

    return (
        <div id="navbar">
            <Link onClick={(e) => handleOnNavItemClick(e)} to="/"><h2 id="navbar_title">Admin Panel</h2></Link>
            <div id="navbar_mobile">
                <div id="navbar_mobile_menu" onClick={ handleMenu }>
                    {Menu ? <FaTimes/> : <FaBars/>}
                </div>
                <Link onClick={(e) => handleOnNavItemClick(e)} to="/" className="navbar_mobile_title_wrap"><h3 id="navbar_mobile_title">Admin Panel</h3></Link>
            </div>
            <div id="navabr_links" data-active="false">
                {/* nav item start */}
                <div className="navbar_item">
                    <div className="navbar_toggle_link">Blog</div>
                    <div className="navbar_dropdown_wrapper">
                        <Link onClick={(e) => handleOnNavItemClick(e)} id="nav-blogs" className="navbar_link" data-active={Active === "nav-blogs" ? "active" : "false"} to="/blogs">All</Link>
                        <Link onClick={(e) => handleOnNavItemClick(e)} id="nav-blog" className="navbar_link" data-active={Active === "nav-blog" ? "active" : "false"} to="/blog">New</Link>
                        <Link onClick={(e) => handleOnNavItemClick(e)} id="nav-custom-blog" className="navbar_link" data-active={Active === "nav-custom-blog" ? "active" : "false"} to="/custom-blog">Custom</Link>
                    </div>
                </div>
                {/* nav item end */}
                {/* nav item start */}
                <div className="navbar_item">
                    <div className="navbar_toggle_link">Project</div>
                    <div className="navbar_dropdown_wrapper">
                        <Link onClick={(e) => handleOnNavItemClick(e)} id="nav-projects" className="navbar_link" data-active={Active === "nav-projects" ? "active" : "false"} to="/projects">All</Link>
                        <Link onClick={(e) => handleOnNavItemClick(e)} id="nav-project" className="navbar_link" data-active={Active === "nav-project" ? "active" : "false"} to="/project">New</Link>
                    </div>
                </div>
                {/* nav item end */}
                {/* nav item start */}
                <div className="navbar_item">
                    <div className="navbar_toggle_link">Team</div>
                    <div className="navbar_dropdown_wrapper">
                        <Link onClick={(e) => handleOnNavItemClick(e)} id="nav-team" className="navbar_link" data-active={Active === "nav-team" ? "active" : "false"} to="/team">All</Link>
                        <Link onClick={(e) => handleOnNavItemClick(e)} id="nav-member" className="navbar_link" data-active={Active === "nav-member" ? "active" : "false"} to="/member">New</Link>
                    </div>
                </div>
                {/* nav item end */}
                {/* nav item start */}
                <div className="navbar_item">
                    <div className="navbar_toggle_link">Reviews</div>
                    <div className="navbar_dropdown_wrapper">
                        <Link onClick={(e) => handleOnNavItemClick(e)} id="nav-review" className="navbar_link" data-active={Active === "nav-review" ? "active" : "false"} to="/review">All</Link>
                    </div>
                </div>
                {/* nav item end */}
                {/* nav item start */}
                <div className="navbar_item">
                    <div className="navbar_toggle_link">Customers</div>
                    <div className="navbar_dropdown_wrapper">
                        <Link onClick={(e) => handleOnNavItemClick(e)} id="nav-customers" className="navbar_link" data-active={Active === "nav-customers" ? "active" : "false"} to="/customers">Mail Message</Link>
                    </div>
                </div>
                {/* nav item end */}
                {/* nav item start */}
                <div className="navbar_item">
                    <div className="navbar_toggle_link">Social Media</div>
                    <div className="navbar_dropdown_wrapper">
                        <Link onClick={(e) => handleOnNavItemClick(e)} id="nav-socialMedia" className="navbar_link" data-active={Active === "nav-socialMedia" ? "active" : "false"} to="/socialMedia">Update</Link>
                    </div>
                </div>
                {/* nav item end */}
                {/* nav item start */}
                {
                    role !== 'user' ? 
                    <div className="navbar_item">
                        <div className="navbar_toggle_link">Users</div>
                        <div className="navbar_dropdown_wrapper">
                            <Link onClick={(e) => handleOnNavItemClick(e)} id="nav-users" className="navbar_link" data-active={Active === "nav-users" ? "active" : "false"} to="/users">All</Link>
                        </div>
                    </div> : <></>
                }
                {/* nav item end */}
                <div className="navbar_bottom">
                    <Link onClick={(e) => handleOnNavItemClick(e)} to='/settings' id="navbar_setting" ><AiFillSetting/></Link>
                    <FiLogOut onClick={() => handleOnLogout()} id="navbar_logout"/>
                </div>
            </div>
        </div>
    )
}

export default Navbar
