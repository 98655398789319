import React, { useEffect, useState } from 'react'
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import uuid from 'react-uuid'
import { FaPlus, FaTimes } from 'react-icons/fa'
import { NotificationManager } from 'react-notifications'
import axios from 'axios';
import { server } from '../../Config';
import { LinearProgress } from '@material-ui/core';
import { BsImage } from 'react-icons/bs'

Quill.register('modules/imageResize', ImageResize);

export default function CustomBlogForm() {
  const [Loading, setLoading] = useState(true)
  const [Tags, setTags] = useState([])
  const [State, setState] = useState({
    editorHtml: ''
  })
  var Editor = {}

  let token = window.localStorage.getItem('jwareAutomationSerialNo')

  function handleChange(html) {
    setState({ editorHtml: html });
  }

  const postId = window.location.pathname.split('/')[2]

  Editor.modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    }
  };

  /*
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  Editor.formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video'
  ];

  useEffect(() => {

    if (postId) {
      setLoading(true)

      axios({
        method: "POST",
        url: server + 'api/getBlog',
        data: {
          id: postId,
          path: 'unknown'
        }
      })
        .then(res => {
          setLoading(false)
          if (res.data.data.length === 0) {
            NotificationManager.info("Data Not Found");
          } else {
            setDataToFields(res.data.data)
          }
        })
        .catch(err => {
          setLoading(false)
          console.log(err)
          if (err.response) {
            NotificationManager.error(err.response.data.message);
          } else {
            NotificationManager.error('Error');
          }
        })
    } else {
      setLoading(false)
    }
  }, [postId])

  function handleImageFile(e, labelId, previewId) {
    let target = e.target;
    let label = document.getElementById(labelId)
    let preview = document.getElementById(previewId)
    let files = target.files;
    const reader = new FileReader();

    reader.addEventListener("load", function () {
      // convert image file to base64 string
      preview.dataset.active = "true"
      preview.src = reader.result;
    }, false);

    if (files[0]) {
      reader.readAsDataURL(files[0]);
      label.textContent = files[0].name
    }
  }

  function setDataToFields(data) {
    let inputs = document.getElementsByClassName('BlogForm-data')

    for (let input of inputs) {
      let title = input.id.split('-')[1]
      if (input.type === 'file') {
        let img = document.getElementById('BlogForm-image-preview')
        img.dataset.active = "true"
        img.src = server + data.image
      } else if (input.type === 'date') {
        let splitDate = data.date.split('/')
        input.value = `${splitDate[2]}-${("0" + splitDate[0]).slice(-2)}-${("0" + splitDate[1]).slice(-2)}`

      } else if (title === 'tags') {
        if (Array.isArray(data.tags)) setTags(data.tags.map((value, i) => {
          return {
            id: uuid(),
            key: i,
            label: value
          }
        }))
      } else {
        input.defaultValue = data.[title]
        input.value = data.[title]
      }
    }

    if (data.html) {
      setState({
        editorHtml: data.html
      })
    }
  }

  function handleOnAddTag(e, inputId) {
    e.preventDefault()
    let input = document.getElementById(inputId)
    let tag = input.value.trim()

    if (tag !== '') {
      setTags([...Tags, { id: uuid(), key: Tags.length, label: tag }])
      input.value = ''
    }
  }

  function handleOnTagRemove(chipToDelete) {
    setTags((chips) => chips.filter((chip) => chip.id !== chipToDelete.id));
    //document.getElementById(`BlogForm_tag_${tag}`).style.display = 'none'
  }

  function setFormData(inputs) {
    let data = new FormData();

    if (postId) {
      data.append('id', postId)
      data.append('html', State.editorHtml)
      for (let input of inputs) {
        let title = input.id.split('-')[1]
        if (input.type === 'file') {
          if (input.files[0]) data.append(title, input.files[0])
        } else if (input.type === 'date') {
          let splitDate = input.value.split('-')
          data.append(title, `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`)
        } else if (title === 'tags') {
          data.append(title, Tags.map(i => i.label).join())
        }
        else {
          data.append(title, input.value)
        }
      }
    } else {
      data.append('html', State.editorHtml)
      for (let input of inputs) {
        let title = input.id.split('-')[1]
        if (input.value) {
          if (input.type === 'file') {
            data.append(title, input.files[0])
          } else if (input.type === 'date') {
            let splitDate = input.value.split('-')
            data.append(title, `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`)
          }
          else {
            data.append(title, input.value)
          }
        } else if (title === 'tags') {
          data.append(title, Tags.map(i => i.label).join())
        }
      }
    }

    return data
  }

  function validateInput(inputs) {
    for (let input of inputs) {
      if (postId) {
        if (input.value === '' && input.defaultValue !== '') {
          NotificationManager.error(`${input.id.split('-')[1]} is requied`)
          input.dataset.valid = "false"
          return false
        }
      } else {
        if (input.value === '') {
          NotificationManager.error(`${input.id.split('-')[1]} is requied`)
          input.dataset.valid = "false"
          return false
        }
      }
    }

    if (Tags.length === 0) {
      NotificationManager.error(`Tags are requied`)
      return false
    }

    if (!State.editorHtml) {
      NotificationManager.error(`Editor content are requied`)
      return false
    }

    return true

  }

  function handleOnSubmit(e) {
    let inputs = document.getElementsByClassName('BlogForm-input')
    let data = document.getElementsByClassName('BlogForm-data')

    e.preventDefault()
    if (validateInput(inputs)) {

      let formData = setFormData(data)

      if (postId) {
        axios({
          method: "PATCH",
          url: server + 'api/updateCustomBlog',
          headers: {
            authorization: 'Bearer ' + token
          },
          data: formData
        })
          .then(res => {
            NotificationManager.success(res.data.message, res.data.status);
            window.location.reload();
          })
          .catch(err => {
            if (err.response) {
              NotificationManager.error(err.response.data.message);
            } else {
              NotificationManager.error('Error');
            }
          })
      } else {
        axios({
          method: "POST",
          url: server + 'api/addCustomBlog',
          headers: {
            authorization: 'Bearer ' + token
          },
          data: formData
        })
          .then(res => {
            NotificationManager.success(res.data.message, res.data.status);
            window.location.reload();
          })
          .catch(err => {
            if (err.response) {
              NotificationManager.error(err.response.data.message);
            } else {
              NotificationManager.error('Error');
            }
          })
      }
    }
  }


  return (
    <div id="CustomBlogForm" className="container">
      <h1 className="main_title"> Custom Blog </h1>
      {
        Loading ? <div className="show_flex_container"><LinearProgress className="show_liner_loading" /></div> :
          <form method="POST" id="BlogForm-form-wrapper">
            {/* input start */}
            <div className="BlogForm_input_wrapper">
              <h3 className="BlogForm_title">Title</h3>
              <input onChange={(e) => { if (e.target.value !== '') { e.target.dataset.valid = "true" } }} data-valid="true" required type="text" id="BlogForm-title" className="input BlogForm-data BlogForm-input" />
            </div>
            {/* input end */}
            {/* input start */}
            <div className="BlogForm_input_wrapper">
              <h3 className="BlogForm_title">Author</h3>
              <input onChange={(e) => { if (e.target.value !== '') { e.target.dataset.valid = "true" } }} data-valid="true" required type="text" id="BlogForm-name" className="input BlogForm-data BlogForm-input" />
            </div>
            {/* input end */}
            {/* input start */}
            <div className="BlogForm_input_wrapper">
              <h3 className="BlogForm_title">Image</h3>
              <div className="BlogForm_image_wrapper">
                {/* label for image */}
                <label htmlFor="BlogForm-image" id="BlogForm-image-label" data-active="true"><BsImage id="BlogForm-label-icon" /></label>
                {/* input image */}
                <input onChange={(e) => { if (e.target.value !== '') { e.target.dataset.valid = "true" } }} data-valid="true" required onChange={(e) => handleImageFile(e, 'BlogForm-image-label', 'BlogForm-image-preview')} type="file" accept="image/png, image/jpeg" id="BlogForm-image" className="input BlogForm-data BlogForm-input" />
                {/* preview */}
                <img src="" alt="preview" id="BlogForm-image-preview" data-active="false" />
              </div>
            </div>
            {/* input end */}
            {/* input start */}
            <div className="BlogForm_input_wrapper">
              <h3 className="BlogForm_title">Date</h3>
              <input onChange={(e) => { if (e.target.value !== '') { e.target.dataset.valid = "true" } }} data-valid="true" required type="date" id="BlogForm-date" className="input BlogForm-data BlogForm-input" />
            </div>
            {/* input end */}
            {/* input start */}
            <div className="BlogForm_input_wrapper">
              <h3 className="BlogForm_title">Category</h3>
              <input onChange={(e) => { if (e.target.value !== '') { e.target.dataset.valid = "true" } }} data-valid="true" required type="text" id="BlogForm-category" className="input BlogForm-data BlogForm-input" />
            </div>
            {/* input end */}
            {/* input start */}
            <div className="BlogForm_input_wrapper">
              <h3 className="BlogForm_title">Tags</h3>
              <div className="BlogForm_tags_content_wrapper">
                <input onChange={(e) => { if (e.target.value !== '') { e.target.dataset.valid = "true" } }} data-valid="true" type="text" id="BlogForm-tags" className="input BlogForm-data" />
                <div onClick={(e) => handleOnAddTag(e, 'BlogForm-tags')} className="button"> <FaPlus /></div>
              </div>

              <ul id="BlogForm_display_tags" className="display_tags_wrapper">
                {
                  Tags.map((data) => {
                    return (
                      <li key={data.id} id={`BlogForm_tag_${data.label}`} className="BlogForm_tag" > {data.label} <FaTimes className="BlogForm_tag_exit" onClick={() => handleOnTagRemove(data)} /> </li>
                    )
                  })
                }
              </ul>
            </div>
            {/* input end */}

            <div className="editor">
              <ReactQuill
                theme={State.theme}
                onChange={handleChange}
                value={State.editorHtml}
                modules={Editor.modules}
                formats={Editor.formats}
                bounds={'#root'}
              />
            </div>

            <input style={{ marginTop: '30px' }} type="submit" className="button" onClick={(e) => handleOnSubmit(e)} value="submit" />
          </form>
      }
    </div>
  )
}
