/* eslint-disable */

import { LinearProgress } from '@material-ui/core'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import {BiMailSend} from 'react-icons/bi'
import {IoSearch} from 'react-icons/io5'
import { NotificationManager } from 'react-notifications'
import DeletePopup from '../../components/DeletePopup'
import {server} from '../../Config'

function Customer() {

    const [Posts, setPosts] = useState([])
    const [Loading, setLoading] = useState(true)
    const [DeletePopUpState, setDeletePopUpState] = useState(false)
    const [DeletePost, setDeletePost] = useState({})

    function OnDeleteConfirm() {
        setDeletePopUpState(false)
        if(DeletePost.id !== '' && DeletePost.email !== ''){
            let Id = DeletePost.id;
            let email = DeletePost.email;
            let post = document.getElementById(Id)

            axios({
                method: "Delete",
                url: server+'api/deleteCustomer',
                headers: {
                    authorization: 'Bearer ' + token
                },
                data: {
                    email: email
                } 
            })
            .then(res => {
                NotificationManager.success(res.data.message, res.data.status);
                post.style.display = 'none'
            })
            .catch(err => {
                if(err.response){
                    NotificationManager.error(err.response.data.message);
                }else{
                    NotificationManager.error('Error');
                }
            })
        }
    }

    function handleDeletePopUpStateChange(state) {
        setDeletePopUpState(state)
    }

    let token = window.localStorage.getItem('jwareAutomationSerialNo')

    let path = window.location.pathname.split('/')[1]

    useEffect(() => {
        setLoading(true)
        if(path === 'customers'){
            axios({
                method: "GET",
                url: server+'api/getAllCustomers',
                headers: {
                    authorization: 'Bearer ' + token
                },
            })
            .then(res => {
                if(res.data.data){
                    setPosts(res.data.data)
                    NotificationManager.success('Customer Data Loaded', res.data.status);
                    setLoading(false)
                }else{
                    NotificationManager.info('Data Not Found');
                }
            })
            .catch(err => {
                if(err.response){
                    NotificationManager.error(err.response.data.message);
                }else{
                    NotificationManager.error('Error');
                }
            })
        }
    }, [])


    function handleOnSearch() {
        let value = document.getElementById('customers_search_txt').value

        if(path === 'customers'){
            setLoading(true)
            axios({
                method: "POST",
                url: server+'api/getCustomersFilterd',
                headers: {
                    authorization: 'Bearer ' + token
                },
                data: {
                    field: 'email',
                    value: value,
                    sort: {
                      field: 'email',
                      order: 'asc'
                    }
                }
            })
            .then(res => {
                setPosts(res.data.data)
                if(res.data.data.length === 0){
                    NotificationManager.info('No Data Found');
                }else{
                    NotificationManager.success('Customer Data Loaded', res.data.status);
                }
                setLoading(false)
            })
            .catch(err => {
                if(err.response){
                    NotificationManager.error(err.response.data.message);
                }else{
                    NotificationManager.error('Error');
                }
            })
        }
    }

    function handleOnValueChange(e) {
        if(e.target.value === ''){
            setLoading(true)
            if(path === 'customers'){
                axios({
                    method: "GET",
                    url: server+'api/getAllCustomers',
                    headers: {
                        authorization: 'Bearer ' + token
                    }
                })
                .then(res => {
                    setPosts(res.data.data)
                    if(res.data.data.length === 0){
                        NotificationManager.info('No Data Found');
                    }else{
                        NotificationManager.success('User Data Loaded', res.data.status);
                    }
                    setLoading(false)
                })
                .catch(err => {
                    if(err.response){
                        NotificationManager.error(err.response.data.message);
                    }else{
                        NotificationManager.error('Error');
                    }
                })
            }
        }
    }

    function handleOnSendMessage() {
        let message = document.getElementById('customers_add_new')
        axios({
            method: "POST",
            url: server+'api/sendForAllCustomers',
            headers: {
                authorization: 'Bearer ' + token
            },
            data: {
                message: message.value
            }
        })
        .then(res => {
            NotificationManager.success(res.data.message, res.data.status);
        })
        .catch(err => {
            if(err.response){
                NotificationManager.error(err.response.data.message);
            }else{
                NotificationManager.error('Error');
            }
        })
    }

    function handleOnDelete(email, Id) {
        setDeletePopUpState(true)
        setDeletePost({
            id: Id,
            email: email
        })
    }

    return (
        <div id="customers" className="container">

            <h1 className="customers_title">Customers</h1>

            {/* customers search start */}
            <div className="customers_search_wrapper">
                <input id="customers_search_txt" onChange={(e) => handleOnValueChange(e)} className="input" placeholder="search emails ..." data-valid="true" />
                <button id="customers_search_btn" className="button" onClick={() => handleOnSearch()}><IoSearch/></button>
            </div>
            {/* customers search end */}

            <div className="customers_add_new_wrapper">
                <p className="customers_add_new_txt"> send message </p>
                <div className="customers_new_admin_wrapper">
                    <textarea id="customers_add_new" className="input" placeholder="Message ...." data-valid="true"></textarea>
                    <button id="customers_add_new_btn" className="button" onClick={() => handleOnSendMessage()}><BiMailSend/></button>
                </div>
            </div>

            <div className="customers_table">
                <div className="customers_row">
                    <h3 className="customers_head">Email</h3>
                    <h3 className="customers_head"></h3>
                </div>

                {
                    Loading ? <div className="show_flex_container"><LinearProgress className="show_liner_loading" /></div> :
                    Posts.map(post => {
                        return (
                            <div id={post._id} key={post._id} className="customers_row">
                                <div className="customers_data">{post.email}</div>
                                <div className="customers_data">
                                    <button onClick={() => handleOnDelete(post.email, post._id)} className="delete">Delete</button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <DeletePopup state={DeletePopUpState} onClick={() => OnDeleteConfirm()} onStateChange={(state) => handleDeletePopUpStateChange(state)}  />

        </div>
    )
}

export default Customer
