import React from 'react'

function Home() {
    return (
        <div id="home" className="container">
            <h1>Welcome To jWare Automation !</h1>
            <h2>Admin Panel</h2>
        </div>
    )
}

export default Home
