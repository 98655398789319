import { Route, Switch } from 'react-router';
import Navbar from './components/Navbar';
import BlogForm from './pages/Blog/BlogForm';
import Blogs from './pages/Blog/Show';
import Home from './pages/Home';
import './scss/App.scss';
import TeamForm from './pages/Team/TeamForm';
import Team from './pages/Team/Team';
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import NewBlogForm from './pages/Blog/NewBlog';
import NewTeamForm from './pages/Team/NewMember';
import Project from './pages/Project/Project';
import NewProjectForm from './pages/Project/NewProject';
import ProjectForm from './pages/Project/ProjectForm';
import NotFound from './pages/NotFound';
import Review from './pages/Review/Review';
import Login from './pages/Login';
import User from './pages/User/User';
import Settings from './pages/Settings';
import Customer from './pages/Customers/Customers';
import SocialMedia from './pages/SocialMedia/SocialMedia';
import CustomBlogForm from './pages/Blog/CustomBlogForm';

function App() {
  //window.localStorage.removeItem('jwareAutomationStatus')

  const Loged = window.localStorage.getItem('jwareAutomationStatus')
  const ExpireIn = window.localStorage.getItem('jwareAutomationDate')

  setInterval(() => {
    let d = new Date()
    let now = d.getTime()

    if(now >= parseInt(ExpireIn)){
      window.localStorage.removeItem('jwareAutomationStatus')
      window.localStorage.removeItem('jwareAutomationSerialNo')
      window.localStorage.removeItem('jwareAutomationRole')
      window.localStorage.removeItem('jwareAutomationDate')
      window.localStorage.removeItem('jwareAutomationEmail')
      window.location.assign(window.location.origin)
    }
  }, 1000);

  return (
    <div className="App">
      {
        Loged ? <> 
          <Navbar/>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/updateProject" component={ProjectForm} />
            <Route path="/project" component={NewProjectForm} />
            <Route path="/projects" component={Project} />
            <Route path="/team" component={Team} />
            <Route path="/blogs" component={Blogs} />
            <Route path="/blog" component={NewBlogForm} />
            <Route path="/updateBlog" component={BlogForm} />
            <Route path="/member" component={NewTeamForm} />
            <Route path="/updateMember" component={TeamForm} />
            <Route path="/review" component={Review} />
            <Route path="/users" component={User} />
            <Route path="/customers" component={Customer} />
            <Route path="/socialMedia" component={SocialMedia} />
            <Route path="/settings" component={Settings} />
            <Route path="/custom-blog" component={CustomBlogForm} />

            <Route component={NotFound} />
          </Switch>
        </> : <Login/>
      }
      <NotificationContainer/>
    </div>
  );
}

export default App;
