/* eslint-disable */

import React, { useEffect, useState } from 'react'
import {BsImage} from 'react-icons/bs'
import axios from 'axios'
import { NotificationManager } from 'react-notifications'
import {server} from '../../Config'
import { LinearProgress } from '@material-ui/core'

function ProjectForm() {
    
    const [Tags, setTags] = useState([])
    const [Loading, setLoading] = useState(false)

    let token = window.localStorage.getItem('jwareAutomationSerialNo')

    const postId = window.location.pathname.split('/')[2]

    useEffect(() => {

        if(postId){
            setLoading(true)
            axios({
                method: "GET",
                url: server+'api/getProject/'+postId
            })
            .then(res => {
                setLoading(false)
                if(res.data.data.length === 0){
                    NotificationManager.info("Data Not Found");
                }else{
                    setDataToFields(res.data.data)
                }
            })
            .catch(err => {
                console.log(err)
                if(err.response){
                    NotificationManager.error(err.response.data.message);
                }else{
                    NotificationManager.error('Error');
                }
            })
        }
    }, [])

    function handleImageFile(e, labelId, previewId) {
        let target = e.target;
        let label = document.getElementById(labelId)
        let preview = document.getElementById(previewId)
        let files = target.files;
        const reader = new FileReader();

        reader.addEventListener("load", function () {
            // convert image file to base64 string
            preview.dataset.active = "true"
            preview.src = reader.result;
        }, false);

        if(files[0]){
            reader.readAsDataURL(files[0]);
            label.textContent = files[0].name
        }
    }

    function validateInput(inputs) {    
        for (let input of inputs){
            if(postId){
                if(input.value === '' && input.defaultValue !==''){
                    NotificationManager.error(`${input.id.split('-')[1]} is requied`)
                    input.dataset.valid = "false"
                    return false
                }
            }else{
                if(input.value === ''){
                    NotificationManager.error(`${input.id.split('-')[1]} is requied`)
                    input.dataset.valid = "false"
                    return false
                }
            }
        }

        return true

    }

    function setDataToFields(data) {
        let inputs = document.getElementsByClassName('ProjectForm-data')
        for (let input of inputs){
            let title = input.id.split('-')[1]
            if(input.type === 'file'){
                let img = document.getElementById('ProjectForm-image-preview')
                img.dataset.active = "true"
                img.src = server+data.image
            }else if(input.type === 'date'){
                let splitDate = data.date.split('/')
                input.value = `${splitDate[2]}-${("0"+splitDate[0]).slice(-2)}-${("0"+splitDate[1]).slice(-2)}`

            }else if(title === 'tags'){
                setTags(data.tags)
            }
            else{
                input.defaultValue = data.[title]
                input.value = data.[title]
            }
        }
    }

    function setFormData(inputs) {
        let data = new FormData();

        if(postId){
            data.append('id', postId)
            for (let input of inputs){
                let title = input.id.split('-')[1]
                if(input.value !== input.defaultValue){
                    if(input.type === 'file'){
                        data.append(title, input.files[0])
                    }else if(input.type === 'date'){
                        let splitDate = input.value.split('-')
                        data.append(title, `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`)
                    }
                    else{
                        data.append(title, input.value)
                    }
                }else if(title === 'tags'){
                    data.append(title, Tags)
                }
            }
        }else{
            for (let input of inputs){
                let title = input.id.split('-')[1]
                if(input.value){
                    if(input.type === 'file'){
                        data.append(title, input.files[0])
                    }else if(input.type === 'date'){
                        let splitDate = input.value.split('-')
                        data.append(title, `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`)
                    }
                    else{
                        data.append(title, input.value)
                    }
                }else if(title === 'tags'){
                    data.append(title, Tags)
                }
            }
        }

        return data
    }

    function handleOnSubmit(e) {
        let inputs = document.getElementsByClassName('ProjectForm-input')
        let data = document.getElementsByClassName('ProjectForm-data')

        e.preventDefault()
        if(validateInput(inputs)){

            let formData = setFormData(data)

            if(postId){
                axios({
                    method: "PATCH",
                    url: server+'api/updateProject',
                    headers: {
                        authorization: 'Bearer ' + token
                    },
                    data: formData
                })
                .then(res => {
                    NotificationManager.success(res.data.message, res.data.status);
                    window.location.reload();
                })
                .catch(err => {
                    if(err.response){
                        NotificationManager.error(err.response.data.message);
                    }else{
                        NotificationManager.error('Error');
                    }
                })
            }else{
                axios({
                    method: "POST",
                    url: server+'api/addProject',
                    headers: {
                        authorization: 'Bearer ' + token
                    },
                    data: formData
                })
                .then(res => {
                    NotificationManager.success(res.data.message, res.data.status);
                    window.location.reload();
                })
                .catch(err => {
                    if(err.response){
                        NotificationManager.error(err.response.data.message);
                    }else{
                        NotificationManager.error('Error');
                    }
                })
            }
        }
    }

    return (
        <div id="ProjectForm" className="container">

            <h1 className="main_title"> Project </h1>

            {
                Loading ? <div className="show_flex_container"><LinearProgress className="show_liner_loading" /></div>  : 
                    <form method="POST" id="ProjectForm-form-wrapper">
                        {/* input start */}
                        <div className="ProjectForm_input_wrapper">
                            <h3 className="ProjectForm_title">Name</h3>
                            <input onChange={(e) => { if(e.target.value !== '') {e.target.dataset.valid="true"} } } data-valid="true" required type="text" id="ProjectForm-name" className="input ProjectForm-data ProjectForm-input" />
                        </div>
                        {/* input end */}
                        {/* input start */}
                        <div className="ProjectForm_input_wrapper">
                            <h3 className="ProjectForm_title">About</h3>
                            <input onChange={(e) => { if(e.target.value !== '') {e.target.dataset.valid="true"} } } data-valid="true" required type="text" id="ProjectForm-info" className="input ProjectForm-data ProjectForm-input" />
                        </div>
                        {/* input end */}
                        {/* input start */}
                        <div className="ProjectForm_input_wrapper">
                            <h3 className="ProjectForm_title">Client</h3>
                            <input onChange={(e) => { if(e.target.value !== '') {e.target.dataset.valid="true"} } } data-valid="true" required type="text" id="ProjectForm-client" className="input ProjectForm-data ProjectForm-input" />
                        </div>
                        {/* input end */}
                        {/* input start */}
                        <div className="ProjectForm_input_wrapper">
                            <h3 className="ProjectForm_title">Image</h3>
                            <div className="ProjectForm_image_wrapper">
                                {/* label for image */}
                                <label htmlFor="ProjectForm-image" id="ProjectForm-image-label" data-active="true"><BsImage id="ProjectForm-label-icon"/></label>
                                {/* input image */}
                                <input onChange={(e) => { if(e.target.value !== '') {e.target.dataset.valid="true"} } } data-valid="true" required onChange={ (e) => handleImageFile(e, 'ProjectForm-image-label', 'ProjectForm-image-preview' )} type="file" accept="image/png, image/jpeg" id="ProjectForm-image" className="input ProjectForm-data ProjectForm-input" />
                                {/* preview */}
                                <img src="" alt="preview" id="ProjectForm-image-preview" data-active="false" />
                            </div>
                        </div>
                        {/* input end */}
                        {/* input start */}
                        <div className="ProjectForm_input_wrapper">
                            <h3 className="ProjectForm_title">Date</h3>
                            <input onChange={(e) => { if(e.target.value !== '') {e.target.dataset.valid="true"} } } data-valid="true" required  type="date" id="ProjectForm-date" className="input ProjectForm-data ProjectForm-input" />
                        </div>
                        {/* input end */}
                        {/* input start */}
                        <div className="ProjectForm_input_wrapper">
                            <h3 className="ProjectForm_title">Category</h3>
                            <input onChange={(e) => { if(e.target.value !== '') {e.target.dataset.valid="true"} } } data-valid="true" required type="text" id="ProjectForm-category" className="input ProjectForm-data ProjectForm-input" />
                        </div>
                        {/* input end */}
                        {/* input start */}
                        <div className="ProjectForm_input_wrapper">
                            <h3 className="ProjectForm_title">Website</h3>
                            <input onChange={(e) => { if(e.target.value !== '') {e.target.dataset.valid="true"} } } data-valid="true" required type="text" id="ProjectForm-website" className="input ProjectForm-data ProjectForm-input" />
                        </div>
                        {/* input end */}
                        {/* input start */}
                        <div className="ProjectForm_input_wrapper">
                            <h3 className="ProjectForm_title">Loaction</h3>
                            <input onChange={(e) => { if(e.target.value !== '') {e.target.dataset.valid="true"} } } data-valid="true" required type="text" id="ProjectForm-location" className="input ProjectForm-data ProjectForm-input" />
                        </div>
                        {/* input end */}
                        <div></div>
                        {/* input start */}
                        <div className="ProjectForm_input_wrapper">
                            <h3 className="ProjectForm_title">Description</h3>
                            <textarea onChange={(e) => { if(e.target.value !== '') {e.target.dataset.valid="true"} } } data-valid="true" required type="text" id="ProjectForm-description" className="input ProjectForm-data ProjectForm-input"></textarea>
                        </div>
                        {/* input end */}

                        <input type="submit" className="button" onClick={(e) => handleOnSubmit(e)} value="submit" />
                    </form>
            } 
        </div>
    )
}

export default ProjectForm
