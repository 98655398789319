import { CircularProgress } from '@material-ui/core'
import axios from 'axios';
import React, { useState } from 'react'
import { NotificationManager } from 'react-notifications';
import Config from '../Config';

function Login() {
    
    const [Spinner, setSpinner] = useState(false)

    function valid() {
        let inputs = document.getElementsByClassName('login_input')

        var emailCheck=/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i; 

        for (let input of inputs){
            if(input.value === ''){
                NotificationManager.error(`${input.id.split("_")[1]} is requied`)
                return false
            }else if (input.type === 'email'){
                if(!emailCheck.test(input.value)){
                    NotificationManager.error(`${input.id.split("_")[1]} is not valid`)
                    return false
                }
            }
        }

        return true

    }

    function handleOnLogin() {
        setSpinner(true)
        let email = document.getElementById('login_email')
        let pwd = document.getElementById('login_password')

        if(valid()){

            axios({
                method: 'POST',
                url: Config.server+'api/userLogin',
                data: {
                    email: email.value,
                    password: pwd.value
                }
            })
            .then(res => {

                if(res.data.login){
                    let d = new Date()
                    let expire = d.getTime() + parseInt(3600000)

                    window.localStorage.setItem('jwareAutomationDate', expire)
                    window.localStorage.setItem('jwareAutomationRole', res.data.role)
                    window.localStorage.setItem('jwareAutomationEmail', res.data.email)
                    window.localStorage.setItem('jwareAutomationSerialNo', res.data.token)
                    window.localStorage.setItem('jwareAutomationStatus', 'true')
                    window.location.reload()
                }else{
                    NotificationManager.error('Login Failed')
                }
            })
            .catch(e => {
                console.log(e)
                if(e.response){
                    NotificationManager.error(e.response.data.message)
                }else{
                    NotificationManager.error('Error')
                }
                
                setTimeout(() => {
                    setSpinner(false)
                }, 100);
            })

        }else{
            setSpinner(false)
        }
    }

    return (
        <div id="login">
            <div className="login_grid_item">
                <h1 id="login_title">Login</h1>
                <input className="login_input" placeholder="Email" type="email" id="login_email"/>
                <input className="login_input" placeholder="Password" type="password" id="login_password"/>
                <div id="login_btn_wrap">
                    { Spinner ? <CircularProgress id="login_spinner" /> : <button onClick={() => handleOnLogin()} id="login_btn">Login</button> }
                </div>
            </div>

            <div className="login_grid_item">
                <img src="/images/login.svg" alt="login" id="login_img" />
            </div>
        </div>
    )
}

export default Login
