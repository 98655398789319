/* eslint-disable */

import React, { useEffect, useState } from 'react'
import { FaPlus, FaTimes } from 'react-icons/fa'
import { BsImage } from 'react-icons/bs'
import axios from 'axios'
import { NotificationManager } from 'react-notifications'
import { server } from '../../Config'
import { LinearProgress } from '@material-ui/core'
import uuid from 'react-uuid'

function NewBlogForm() {

    const [Tags, setTags] = useState([])
    const [Loading, setLoading] = useState(false)

    let token = window.localStorage.getItem('jwareAutomationSerialNo')

    const postId = window.location.pathname.split('/')[2]

    useEffect(() => {

        if (postId) {
            setLoading(true)
            axios({
                method: "POST",
                url: server + 'api/getBlog',
                data: {
                    id: postId,
                    path: 'unknown'
                }
            })
                .then(res => {
                    setLoading(false)
                    if (res.data.data.length === 0) {
                        NotificationManager.info("Data Not Found");
                    } else {
                        setDataToFields(res.data.data)
                    }
                })
                .catch(err => {
                    console.log(err)
                    if (err.response) {
                        NotificationManager.error(err.response.data.message);
                    } else {
                        NotificationManager.error('Error');
                    }
                })
        }
    }, [postId])

    function handleImageFile(e, labelId, previewId) {
        let target = e.target;
        let label = document.getElementById(labelId)
        let preview = document.getElementById(previewId)
        let files = target.files;
        const reader = new FileReader();

        reader.addEventListener("load", function () {
            // convert image file to base64 string
            preview.dataset.active = "true"
            preview.src = reader.result;
        }, false);

        if (files[0]) {
            reader.readAsDataURL(files[0]);
            label.textContent = files[0].name
        }
    }

    function handleOnAddTag(e, inputId) {
        e.preventDefault()
        let input = document.getElementById(inputId)
        let tag = input.value.trim()

        if (tag !== '') {
            setTags([...Tags, { id: uuid(), key: Tags.length, label: tag }])
            input.value = ''
        }

    }

    function validateInput(inputs) {
        for (let input of inputs) {
            if (postId) {
                if (input.value === '' && input.defaultValue !== '') {
                    NotificationManager.error(`${input.id.split('-')[1]} is requied`)
                    input.dataset.valid = "false"
                    return false
                }
            } else {
                if (input.value === '') {
                    NotificationManager.error(`${input.id.split('-')[1]} is requied`)
                    input.dataset.valid = "false"
                    return false
                }
            }
        }

        if (Tags.length === 0) {
            NotificationManager.error(`Tags are requied`)
            return false
        }

        return true

    }

    function setDataToFields(data) {
        let inputs = document.getElementsByClassName('BlogForm-data')
        for (let input of inputs) {
            let title = input.id.split('-')[1]
            if (input.type === 'file') {
                let img = document.getElementById('BlogForm-image-preview')
                img.dataset.active = "true"
                img.src = server + data.image
            } else if (input.type === 'date') {
                let splitDate = data.date.split('/')
                input.value = `${splitDate[2]}-${("0" + splitDate[0]).slice(-2)}-${("0" + splitDate[1]).slice(-2)}`

            } else if (title === 'tags') {
                //
            }
            else {
                input.defaultValue = data.[title]
                input.value = data.[title]
            }
        }
    }

    function setFormData(inputs) {
        let data = new FormData();

        if (postId) {
            data.append('id', postId)
            for (let input of inputs) {
                let title = input.id.split('-')[1]
                if (input.value !== input.defaultValue) {
                    if (input.type === 'file') {
                        data.append(title, input.files[0])
                    } else if (input.type === 'date') {
                        let splitDate = input.value.split('-')
                        data.append(title, `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`)
                    }
                    else {
                        data.append(title, input.value)
                    }
                } else if (title === 'tags') {
                    //
                }
            }
        } else {
            for (let input of inputs) {
                let title = input.id.split('-')[1]
                if (input.value) {
                    if (input.type === 'file') {
                        data.append(title, input.files[0])
                    } else if (input.type === 'date') {
                        let splitDate = input.value.split('-')
                        data.append(title, `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`)
                    }
                    else {
                        data.append(title, input.value)
                    }
                } else if (title === 'tags') {
                    let tags = []
                    Tags.forEach(data => {
                        tags.push(data.label)
                    })
                    data.append(title, tags)
                }
            }
        }

        return data
    }

    function handleOnSubmit(e) {
        let inputs = document.getElementsByClassName('BlogForm-input')
        let data = document.getElementsByClassName('BlogForm-data')

        e.preventDefault()
        if (validateInput(inputs)) {

            let formData = setFormData(data)

            if (postId) {
                axios({
                    method: "PATCH",
                    url: server + 'api/updateBlog',
                    headers: {
                        authorization: 'Bearer ' + token
                    },
                    data: formData
                })
                    .then(res => {
                        NotificationManager.success(res.data.message, res.data.status);
                        window.location.reload();
                    })
                    .catch(err => {
                        if (err.response) {
                            NotificationManager.error(err.response.data.message);
                        } else {
                            NotificationManager.error('Error');
                        }
                    })
            } else {
                axios({
                    method: "POST",
                    url: server + 'api/addBlog',
                    headers: {
                        authorization: 'Bearer ' + token
                    },
                    data: formData
                })
                    .then(res => {
                        NotificationManager.success(res.data.message, res.data.status);
                        window.location.reload();
                    })
                    .catch(err => {
                        if (err.response) {
                            NotificationManager.error(err.response.data.message);
                        } else {
                            NotificationManager.error('Error');
                        }
                    })
            }
        }
    }


    function handleOnTagRemove(chipToDelete) {
        setTags((chips) => chips.filter((chip) => chip.id !== chipToDelete.id));
        //document.getElementById(`BlogForm_tag_${tag}`).style.display = 'none'
    }

    return (
        <div id="BlogForm" className="container">

            <h1 className="main_title"> Blog </h1>

            {
                Loading ? <div className="show_flex_container"><LinearProgress className="show_liner_loading" /></div> :
                    <form method="POST" id="BlogForm-form-wrapper">
                        {/* input start */}
                        <div className="BlogForm_input_wrapper">
                            <h3 className="BlogForm_title">Title</h3>
                            <input onChange={(e) => { if (e.target.value !== '') { e.target.dataset.valid = "true" } }} data-valid="true" required type="text" id="BlogForm-title" className="input BlogForm-data BlogForm-input" />
                        </div>
                        {/* input end */}
                        {/* input start */}
                        <div className="BlogForm_input_wrapper">
                            <h3 className="BlogForm_title">Author</h3>
                            <input onChange={(e) => { if (e.target.value !== '') { e.target.dataset.valid = "true" } }} data-valid="true" required type="text" id="BlogForm-name" className="input BlogForm-data BlogForm-input" />
                        </div>
                        {/* input end */}
                        {/* input start */}
                        <div className="BlogForm_input_wrapper">
                            <h3 className="BlogForm_title">Image</h3>
                            <div className="BlogForm_image_wrapper">
                                {/* label for image */}
                                <label htmlFor="BlogForm-image" id="BlogForm-image-label" data-active="true"><BsImage id="BlogForm-label-icon" /></label>
                                {/* input image */}
                                <input onChange={(e) => { if (e.target.value !== '') { e.target.dataset.valid = "true" } }} data-valid="true" required onChange={(e) => handleImageFile(e, 'BlogForm-image-label', 'BlogForm-image-preview')} type="file" accept="image/png, image/jpeg" id="BlogForm-image" className="input BlogForm-data BlogForm-input" />
                                {/* preview */}
                                <img src="" alt="preview" id="BlogForm-image-preview" data-active="false" />
                            </div>
                        </div>
                        {/* input end */}
                        {/* input start */}
                        <div className="BlogForm_input_wrapper">
                            <h3 className="BlogForm_title">Date</h3>
                            <input onChange={(e) => { if (e.target.value !== '') { e.target.dataset.valid = "true" } }} data-valid="true" required type="date" id="BlogForm-date" className="input BlogForm-data BlogForm-input" />
                        </div>
                        {/* input end */}
                        {/* input start */}
                        <div className="BlogForm_input_wrapper">
                            <h3 className="BlogForm_title">Category</h3>
                            <input onChange={(e) => { if (e.target.value !== '') { e.target.dataset.valid = "true" } }} data-valid="true" required type="text" id="BlogForm-category" className="input BlogForm-data BlogForm-input" />
                        </div>
                        {/* input end */}
                        {/* input start */}
                        <div className="BlogForm_input_wrapper">
                            <h3 className="BlogForm_title">Tags</h3>
                            <div className="BlogForm_tags_content_wrapper">
                                <input onChange={(e) => { if (e.target.value !== '') { e.target.dataset.valid = "true" } }} data-valid="true" type="text" id="BlogForm-tags" className="input BlogForm-data" />
                                <div onClick={(e) => handleOnAddTag(e, 'BlogForm-tags')} className="button"> <FaPlus /></div>
                            </div>

                            <ul id="BlogForm_display_tags" className="display_tags_wrapper">
                                {
                                    Tags.map((data) => {
                                        return (
                                            <li key={data.id} id={`BlogForm_tag_${data.label}`} className="BlogForm_tag" > {data.label} <FaTimes className="BlogForm_tag_exit" onClick={() => handleOnTagRemove(data)} /> </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        {/* input end */}
                        <div></div>
                        {/* input start */}
                        <div className="BlogForm_input_wrapper">
                            <h3 className="BlogForm_title">Description</h3>
                            <textarea onChange={(e) => { if (e.target.value !== '') { e.target.dataset.valid = "true" } }} data-valid="true" required type="text" id="BlogForm-description" className="input BlogForm-data BlogForm-input"></textarea>
                        </div>
                        {/* input end */}

                        <input type="submit" className="button" onClick={(e) => handleOnSubmit(e)} value="submit" />
                    </form>
            }
        </div>
    )
}

export default NewBlogForm
