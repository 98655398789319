/* eslint-disable */

import { FormControl, InputLabel, LinearProgress, makeStyles, MenuItem, Select } from '@material-ui/core'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import {BsFillPlusSquareFill} from 'react-icons/bs'
import {IoSearch} from 'react-icons/io5'
import { NotificationManager } from 'react-notifications'
import DeletePopup from '../../components/DeletePopup'
import {server} from '../../Config'

const useStyles = makeStyles((theme) => ({
    formControl: {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

function User() {

    const [Posts, setPosts] = useState([])
    const [Loading, setLoading] = useState(true)
    const classes = useStyles();
    const [type, setType] = useState('user');
    const [DeletePopUpState, setDeletePopUpState] = useState(false)
    const [DeletePost, setDeletePost] = useState({})

    function OnDeleteConfirm() {
        setDeletePopUpState(false)
        if(DeletePost.id !== '' && DeletePost.email !== ''){
            let Id = DeletePost.id;
            let email = DeletePost.email;
            let post = document.getElementById(Id)

            axios({
                method: "Delete",
                url: server+'api/deleteUser',
                headers: {
                    authorization: 'Bearer ' + token
                },
                data: {
                    email: email
                } 
            })
            .then(res => {
                NotificationManager.success(res.data.message, res.data.status);
                post.style.display = 'none'
            })
            .catch(err => {
                if(err.response){
                    NotificationManager.error(err.response.data.message);
                }else{
                    NotificationManager.error('Error');
                }
            })
        }
    }

    function handleDeletePopUpStateChange(state) {
        setDeletePopUpState(state)
    }
  
    const handleChange = (event) => {
        setType(event.target.value);
    };
    
    let token = window.localStorage.getItem('jwareAutomationSerialNo')
    let AdminRole = window.localStorage.getItem('jwareAutomationRole')

    let path = window.location.pathname.split('/')[1]

    useEffect(() => {
        setLoading(true)
        if(path === 'users'){
            axios({
                method: "GET",
                url: server+'api/getAllUsers',
                headers: {
                    authorization: 'Bearer ' + token
                },
            })
            .then(res => {
                if(res.data.data){
                    setPosts(res.data.data)
                    NotificationManager.success('User Data Loaded', res.data.status);
                    setLoading(false)
                }else{
                    NotificationManager.info('Data Not Found');
                }
            })
            .catch(err => {
                if(err.response){
                    NotificationManager.error(err.response.data.message);
                }else{
                    NotificationManager.error('Error');
                }
            })
        }
    }, [])


    function handleOnSearch() {
        let value = document.getElementById('user_search_txt').value

        if(path === 'users'){
            setLoading(true)
            axios({
                method: "POST",
                url: server+'api/getUsersFilterd',
                headers: {
                    authorization: 'Bearer ' + token
                },
                data: {
                    field: 'email',
                    value: value,
                    sort: {
                      field: 'email',
                      order: 'asc'
                    }
                }
            })
            .then(res => {
                setPosts(res.data.data)
                if(res.data.data.length === 0){
                    NotificationManager.info('No Data Found');
                }else{
                    NotificationManager.success('User Data Loaded', res.data.status);
                }
                setLoading(false)
            })
            .catch(err => {
                if(err.response){
                    NotificationManager.error(err.response.data.message);
                }else{
                    NotificationManager.error('Error');
                }
            })
        }
    }

    function handleOnValueChange(e) {
        if(e.target.value === ''){
            setLoading(true)
            if(path === 'users'){
                axios({
                    method: "GET",
                    url: server+'api/getAllUsers',
                    headers: {
                        authorization: 'Bearer ' + token
                    }
                })
                .then(res => {
                    setPosts(res.data.data)
                    if(res.data.data.length === 0){
                        NotificationManager.info('No Data Found');
                    }else{
                        NotificationManager.success('User Data Loaded', res.data.status);
                    }
                    setLoading(false)
                })
                .catch(err => {
                    if(err.response){
                        NotificationManager.error(err.response.data.message);
                    }else{
                        NotificationManager.error('Error');
                    }
                })
            }
        }
    }

    function handleOnDelete(email, Id) {
        setDeletePopUpState(true)
        setDeletePost({
            id: Id,
            email: email
        })
    }

    function handleOnNewAdmin() {
        let email = document.getElementById('user_add_new')

        axios({
            method: "POST",
            url: server+'api/addUser',
            headers: {
                authorization: 'Bearer ' + token
            },
            data: {
                role: type,
                email: email.value
            }
        })
        .then(res => {
            NotificationManager.success(res.data.message, res.data.status);
            //window.location.reload()
            setPosts([...Posts, res.data.data])
            email.value = ''
        })
        .catch(err => {
            if(err.response){
                NotificationManager.error(err.response.data.message);
            }else{
                NotificationManager.error('Error');
            }
        })
    }

    return (
        <div id="user" className="container">

            <h1 className="user_title">Users</h1>

            {/* user search start */}
            <div className="user_search_wrapper">
                <input id="user_search_txt" onChange={(e) => handleOnValueChange(e)} className="input" placeholder="search emails ..." data-valid="true" />
                <button id="user_search_btn" className="button" onClick={() => handleOnSearch()}><IoSearch/></button>
            </div>
            {/* user search end */}

            <div className="user_add_new_wrapper">
                <p className="user_add_new_txt"> Add new admin </p>
                <div className="user_new_admin_wrapper">
                    {
                        AdminRole === 'super admin' ?
                            <FormControl variant="filled" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-filled-label">Type</InputLabel>
                                <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={type}
                                onChange={handleChange}
                                >
                                <MenuItem value={'user'}>user</MenuItem>
                                <MenuItem value={'admin'}>admin</MenuItem>
                                </Select>
                            </FormControl> : ''
                    }
                    <div className="user_admin_email_add_new">                        
                        <input id="user_add_new" className="input" placeholder="Email" data-valid="true" />
                        <button id="user_add_new_btn" onClick={() => handleOnNewAdmin()} className="button" ><BsFillPlusSquareFill/></button>
                    </div>
                </div>
            </div>

            <div className="user_table">
                <div className="user_row">
                    <h3 className="user_head">Email</h3>
                    <h3 className="user_head"></h3>
                </div>

                {
                    Loading ? <div className="show_flex_container"><LinearProgress className="show_liner_loading" /></div> :

                    AdminRole === 'super admin'  ?
                        Posts.map(post => {
                            return (
                                <div id={post._id} key={post._id} className="user_row" data-role={post.role}>
                                    <div className="user_data">{post.email}</div>
                                    <div className="user_data">
                                        <button onClick={() => handleOnDelete(post.email, post._id)} className="delete">Delete</button>
                                    </div>
                                </div>
                            )
                        })
                        :
                        Posts.map(post => {
                            if(post.role === 'user') {
                                return (
                                    <div id={post._id} key={post._id} className="user_row">
                                        <div className="user_data">{post.email}</div>
                                        <div className="user_data">
                                            <button onClick={() => handleOnDelete(post.email, post._id)} className="delete">Delete</button>
                                        </div>
                                    </div>
                                )
                            }
                        })
                }
            </div>

            <DeletePopup state={DeletePopUpState} onClick={() => OnDeleteConfirm()} onStateChange={(state) => handleDeletePopUpStateChange(state)}  />

        </div>
    )
}

export default User
